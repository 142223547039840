<!--累计总奖金-->
<template>
  <div>
    <div style="text-align: left;margin-top: 20px">
      <el-date-picker
          size="mini"
          style="width: 130px;"
          v-model="listQuery1.starTime"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          type="date">
      </el-date-picker>
      <el-date-picker
          size="mini"
          value-format="yyyy-MM-dd"
          style="width: 130px;margin:0 5px"
          :picker-options="pickerOptions"
          v-model="listQuery1.endTime"
          type="date">
      </el-date-picker>
      <el-button size="mini" :loading="btnloading" type="primary" @click="getTotalbonus">{{$t('operation.button.search')}}</el-button>
    </div>
    <div style="margin-top: 50px">
<!--      W$隐藏-->
<!--      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.duipeng')}}：<span style="color: green">{{this.totalbonusL.duipeng}} W$</span></p>-->
<!--      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.yeji')}}：<span style="color: green">{{this.totalbonusL.yeji}} W$</span></p>-->
<!--      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.chongxiao')}}：<span style="color: green">{{this.totalbonusL.chongxiao}} W$</span></p>      -->
      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.duipeng')}}：<span style="color: green">{{this.totalbonusL.duipeng}}</span></p>
      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.yeji')}}：<span style="color: green">{{this.totalbonusL.yeji}}</span></p>
      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.chongxiao')}}：<span style="color: green">{{this.totalbonusL.chongxiao}}</span></p>
<!--      一开始就隐藏-->
<!--      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.lvyou')}}：<span style="color: green">{{this.totalbonusL.lvyou}} W$</span></p>-->
      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('ac_type.B2')}}：<span style="color: green">{{this.totalbonusL.che}} W$</span></p>
<!--      W$隐藏-->
<!--      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.qita')}}：<span style="color: green">{{this.totalbonusL.qita}} W$</span></p>-->
<!--      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.yiqi')}}：<span style="color: green">{{this.totalbonusL.yiqi}} W$</span></p>-->
<!--      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('Total.bonus.paid')}}：<span style="color: green">{{this.totalbonusL.zong}} W$</span></p>-->
      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.qita')}}：<span style="color: green">{{this.totalbonusL.qita}}</span></p>
<!--      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('language.yiqi')}}：<span style="color: green">{{this.totalbonusL.yiqi}}</span></p>-->
      <p v-loading="listloading" style="font-size: 30px;font-weight: 600;color: red">{{$t('Total.bonus.paid')}}：<span style="color: green">{{this.totalbonusL.zong}}</span></p>
    </div>
  </div>
</template>

<script>
import {bdSumByTime} from "@/api/rus/api";
import {mapGetters} from "vuex";

export default {
  name: "TotalAccumulatedBonus",
  data(){
    return{
      btnloading:false,
      listloading:false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < 1657728001000;
        }
      },
      listQuery1:{
        starTime:'',
        endTime:'',
        userCode:''
      },
      totalbonusL:{
        zong:0,//总奖金
        yiqi:0,//仪器推荐奖
        duipeng:0,//碰对奖50%
        yeji:0,//业绩发展奖 10%
        chongxiao:0,//重消奖
        lvyou:0,//荣衔奖
        che:0,//荣衔奖
        qita:0,//荣衔奖
      },
      dateRange:''
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
      'ckCode',
      'companyCode',
    ]),
  },
  created() {
    // this.getDate()
  },
  methods:{
    getDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      console.log(year,month)
      if(month === 1){
        this.listQuery1.starTime = (year - 1 + '-' + 12 + '-' + '29').toString()
      }else{
        this.listQuery1.starTime = (year + '-' + month - 1 + '-' + '29').toString()
      }
    },
    getTotalbonus(){
      this.listloading = true
      this.btnloading = true
      this.listQuery1.userCode = this.userCode
      bdSumByTime({...this.listQuery1}).then(res=>{
        if(res.data){
          this.totalbonusL = res.data
        }else{
          this.totalbonusL = {
            zong:0,//总奖金
            yiqi:0,//仪器推荐奖
            duipeng:0,//碰对奖50%
            yeji:0,//业绩发展奖 10%
            chongxiao:0,//重消奖
            lvyou:0,//荣衔奖
            che:0,//荣衔奖
            qita:0,//荣衔奖
          }
        }
        this.listloading = false
        this.btnloading = false
      }).catch(err=>{
        this.listloading = false
        this.btnloading = false
        console.error(err)
      })
    },
  }
}
</script>

<style scoped>

</style>
